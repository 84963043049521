import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/DefaultLayout.tsx";
import { graphql } from 'gatsby';
import { Anchor } from '@twilio-paste/anchor';
import { Callout, CalloutTitle, CalloutText } from '../../../components/callout';
export const pageQuery = graphql`
  {
    mdx(fields: {slug: {eq: "/content/get-support/"}}) {
      fileAbsolutePath
      frontmatter {
        slug
        title
      }
      headings {
        depth
        value
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageAside = makeShortcode("PageAside");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <content>
      <h1>{props.pageContext.frontmatter.title}</h1>
      <p>{props.pageContext.frontmatter.description}</p>
    </content>
    <hr></hr>
    <contentwrapper>
      <PageAside data={props.data.mdx} mdxType="PageAside" />
      <content>
        <h2>{`Get content support`}</h2>
        <h3>{`Content office hours `}{`—`}{` sign-up only`}</h3>
        <p><em parentName="p">{`Content office hours are on Tuesdays. Check the UX Calendar for details.`}</em></p>
        <p><a parentName="p" {...{
            "href": "https://docs.google.com/spreadsheets/d/1KEMjmBdW9Q_dubGxMERP9qU9De3dMKjSsUgSwM1NBBM/edit#gid=0"
          }}>{`Use the sign up sheet`}</a>{` to register for a slot. If there are no sign-ups an hour before the start time, the session may be cancelled.`}</p>
        <ul>
          <li parentName="ul">{`Slots are 30 minutes long. `}</li>
          <li parentName="ul">{`We'll spend 5-10 minutes talking about context and reviewing what you need, then move into problem solving.`}</li>
          <li parentName="ul">{`You can invite your PM! Collaborative sessions are encouraged as they're highly effective.`}</li>
          <li parentName="ul">{`If possible, pull the content into a `}<a parentName="li" {...{
              "href": "https://docs.google.com/document/d/1QSUA5NnwyQzJcTMLDExF1gfW0eRWP7AhmU8L0Sfbr7s/edit?usp=sharing"
            }}>{`CopyDoc template`}</a>{`. This enables us and your team to focus on the words and collaborate async.`}</li>
        </ul>
        <h4>{`What to bring to office hours?`}</h4>
        <p>{`Be bold and bring early work.
Bring your plans to discuss.
Bring lots of ideas and variations of content.
Bring prioritized work.`}</p>
        <p>{`Expect to receive feedback, recommendations, and guidance during the session. Unfortunately, I can't work on your content outside of office hours - so please come prepared!`}</p>
        <h3>{`Training and resources`}</h3>
        <p>{`Look out for information about content training sessions via email and sign up! `}</p>
        <p>{`Check out the latest resource: `}<a parentName="p" {...{
            "href": "https://docs.google.com/document/d/1nerpH9xhsG6obgl-nKWbzwJFQva5ZU6VdNgCRJx-zVY/edit?usp=sharing"
          }}>{`Content for UX`}</a>{`.
This contains the key takeaways from the training session, action points, and tools for you to use.`}</p>
        <h3>{`Quick self-serve checklist`}</h3>
        <p>{`How do you know if your content is "done" or "good"?`}</p>
        <h4>{`The tone is appropriate to the scenario.`}</h4>
        <ul>
          <li parentName="ul">{`A stressful scenario is managed empathetically, with a knowledgeable and encouraging tone. Reassure and inform the user clearly.`}</li>
          <li parentName="ul">{`A win or success is managed genuinely, with a positive and encouraging tone. Celebrate with the user genuinely.`}</li>
          <li parentName="ul">{`A repeated interaction is managed efficiently, with a positive and conversational tone. Support the user's flow by reducing friction.`}</li>
          <li parentName="ul">{`The content sounds like a frinedly specialist.`}</li>
        </ul>
        <h4>{`The content meets the user's immediate needs.`}</h4>
        <ul>
          <li parentName="ul">{`The user has enough information to understand the context and proposition, the impact of an action/decision, and clarity over what happens next.`}</li>
        </ul>
        <h4>{`The words and structure are as simple as they can be.`}</h4>
        <ul>
          <li parentName="ul">{`You've replaced jargon with a simpler term or explained technical language or acronyms.`}</li>
          <li parentName="ul">{`Sentences are short.`}</li>
        </ul>
        <h4>{`The message is clear and unambiguous.`}</h4>
        <ul>
          <li parentName="ul">{`Your content can only be interpreted one way.`}</li>
          <li parentName="ul">{`You've replaced any vague or imprecise language with precise, clear terms.`}</li>
        </ul>
        <h4>{`You've checked it. Twice.`}</h4>
        <ul>
          <li parentName="ul">{`Capitals, punctuation, and spelling all align with this guide.`}</li>
          <li parentName="ul">{`Tip: Read it out loud to yourself. If it doesn't "sound right", consider changing the wording or phrasing.`}</li>
        </ul>
        <h4>{`Someone unfamiliar with the project has read and understood it.`}</h4>
        <ul>
          <li parentName="ul">{`Ask them to read it out loud, then tell you what it means.`}</li>
          <li parentName="ul">{`If their understanding matches your intent, great! Your content is clear.`}</li>
          <li parentName="ul">{`If they stumble on words or don't understand, ask why. Use their feedback to iterate, then try again.`}</li>
        </ul>
        <Callout mdxType="Callout">
  <CalloutTitle as="h4" mdxType="CalloutTitle">Need support with non-product content?</CalloutTitle>
  <CalloutText mdxType="CalloutText">
    {' '}
    Visit the <Anchor href="https://www.twilio.com/brand/guidelines/writing-style" mdxType="Anchor">
      Brand Writing Style Guidelines here
    </Anchor>.
  </CalloutText>
        </Callout>
      </content>
    </contentwrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      